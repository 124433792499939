import {applyMiddleware, combineReducers, compose, createStore} from "redux";
import auth from "./auth";
import message from "./message";
import sidebar from "./sidebar";
import categories from "./categories";
import marques from "./marques";
import enseignes from "./enseignes";
import users from "./users";
import groupusers from "./groupusers";
import objectives from "./objectives";
import orders from "./orders";
import articles from "./articles";
import distributionchannels from "./distributionchannels";
import typeecheances from "./typeecheances";
import routeplans from "./routeplans";
import promotions from "./promotions";
import discounts from "./discounts";
import statistics from "./statistics";
import myappsettings from "./myappsettings";
import codetarifs from "./codetarifs";
import thunk from "redux-thunk";
import {createLogger} from 'redux-logger'
import { routerReducer, routerMiddleware } from 'react-router-redux'


export default function configureStore (history, initialState) {

    const logger = createLogger({
        duration: true,
        level: 'log'
      });

    const reducers = {
        sidebar,
        auth,
        message,
        categories,
        marques,
        enseignes,
        groupusers,
        users,
        objectives,
        orders,
        articles,
        distributionchannels,
        typeecheances,
        routeplans,
        promotions,
        discounts,
        myappsettings,
        statistics,
        codetarifs
    };

    const middleware = [
        thunk,
        routerMiddleware(history),
        logger
    ];

    // In development, use the browser's Redux dev tools extension if installed
    const enhancers = [];
    const isDevelopment = process.env.NODE_ENV === 'development';
    if (isDevelopment && typeof window !== 'undefined' && window.devToolsExtension) {
        enhancers.push(window.devToolsExtension());
    }

    const rootReducer = combineReducers({
        ...reducers,
        routing: routerReducer
    });
    const loadState = () => {
        try {
            const serializedState = localStorage.getItem('state');
            if(serializedState === null) {
                return undefined;
            }
            return JSON.parse(serializedState);
        } catch (e) {
            return undefined;
        }
    };
    const saveState = (state) => {
        try {
            const serializedState = JSON.stringify(state);
            localStorage.setItem('state', serializedState);
        } catch (e) {
            // Ignore write errors;
        }
    };
    const persistedState = Object.assign({}, loadState(), {initialState});
    const store = createStore(
        rootReducer,
        persistedState,
        compose(applyMiddleware(...middleware), ...enhancers, )
    );
    store.subscribe(() => {
        saveState(store.getState());
    });


    return store;
}
