import {
    LISTE_ENSEIGNE_FAIL,
    LISTE_ENSEIGNE_SUCCESS,
    LISTE_ENSEIGNE_LOADING,
    LISTE_ENSEIGNE_ADD_MODAL,
    LISTE_ENSEIGNE_ADD_LOADING,
    LISTE_ENSEIGNE_UPDATE_MODAL,
    LISTE_ENSEIGNE_UPDATE_LOADING,
    LISTE_ENSEIGNE_ITEM_SUCCESS,
    LISTE_ENSEIGNE_ITEM_FAIL,
    LISTE_ENSEIGNE_ITEM_LOADING, LISTE_CATEGORIEENSEIGNE_LOADING, LISTE_CATEGORIEENSEIGNE_DELETE_LOADING
} from "../actions/types";

const initialState = {
    enseignes: [],
    enseignesErrorMessage: null,
    itemDataErrorMessage: null,
    totalRows: 0,
    totalPages: 0,
    itemDataUpdate: null,
    itemData: null,
    loading: false,
    loadingAdd: false,
    loadingUpdate: false,
    loadingItemData: false,
    loadingAddCategorieEnseigne: false,
    loadingDeleteCategorieEnseigne: false,
    showAddModal: false,
    showUpdateModal: false,
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case LISTE_ENSEIGNE_SUCCESS:
            return {
                ...state,
                enseignes: payload.enseignes,
                enseignesErrorMessage: null,
                totalRows: payload.totalRows,
                totalPages: payload.totalPages,
                loading: false
            };

        case LISTE_ENSEIGNE_FAIL:
            return {
                ...state,
                enseignesErrorMessage: payload.message,
                loading: false
            };

        case LISTE_ENSEIGNE_LOADING:
            return {
                ...state,
                // enseignes: payload?.loading ? [] : state.enseignes,loadingItemData
                loading: payload.loading
            };

        case LISTE_ENSEIGNE_ITEM_LOADING:
            return {
                ...state,
                loadingItemData: payload.loading
            };

        case LISTE_CATEGORIEENSEIGNE_LOADING:
            return {
                ...state,
                loadingAddCategorieEnseigne: payload.loading
            };

        case LISTE_CATEGORIEENSEIGNE_DELETE_LOADING:
            return {
                ...state,
                loadingDeleteCategorieEnseigne: payload.loading
            };
            
        case LISTE_ENSEIGNE_ITEM_FAIL:
            return {
                ...state,
                itemDataErrorMessage: payload.message,
                loadingItemData: false
            };

        case LISTE_ENSEIGNE_ADD_LOADING:
            return {
                ...state,
                loadingAdd: payload.loadingAdd
            };

        case LISTE_ENSEIGNE_UPDATE_LOADING:
            return {
                ...state,
                loadingUpdate: payload.loadingUpdate
            };

        case LISTE_ENSEIGNE_ADD_MODAL:
            return {
                ...state,
                showAddModal: payload?.show,
            };

        case LISTE_ENSEIGNE_UPDATE_MODAL:
            return {
                ...state,
                showUpdateModal: payload?.show,
                itemDataUpdate: payload?.data
            };
        case LISTE_ENSEIGNE_ITEM_SUCCESS:
            return {
                ...state,
                itemData: payload.itemData
            };

        default:
            return state;
    }
}
