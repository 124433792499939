export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const LOGOUT_FAIL = "LOGOUT_FAIL";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";
export const PENDING_MESSAGE = "PENDING_MESSAGE";
export const SET = "set";

export const LISTE_CATEGORIE_LOADING = "LISTE_CATEGORIE_LOADING";
export const LISTE_CATEGORIE_SUCCESS = "LISTE_CATEGORIE_SUCCESS";
export const LISTE_CATEGORIE_FAIL = "LISTE_CATEGORIE_FAIL";
export const LISTE_CATEGORIE_UPDATE_MODAL = "LISTE_CATEGORIE_UPDATE_MODAL";
export const LISTE_CATEGORIE_ADD_MODAL = "LISTE_CATEGORIE_ADD_MODAL";
export const LISTE_CATEGORIE_UPDATE_LOADING = "LISTE_CATEGORIE_UPDATE_LOADING";
export const LISTE_CATEGORIE_ADD_LOADING = "LISTE_CATEGORIE_ADD_LOADING";
export const LISTE_CATEGORIE_ADD_SUCCESS = "LISTE_CATEGORIE_ADD_SUCCESS";
export const LISTE_CATEGORIE_ADD_FAIL = "LISTE_CATEGORIE_ADD_FAIL";
export const LISTE_CATEGORIE_UPDATE_SUCCESS = "LISTE_CATEGORIE_UPDATE_SUCCESS";
export const LISTE_CATEGORIE_UPDATE_FAIL = "LISTE_CATEGORIE_UPDATE_FAIL";

export const LISTE_ENSEIGNE_LOADING = "LISTE_ENSEIGNE_LOADING";
export const LISTE_ENSEIGNE_SUCCESS = "LISTE_ENSEIGNE_SUCCESS";
export const LISTE_ENSEIGNE_FAIL = "LISTE_ENSEIGNE_FAIL";
export const LISTE_ENSEIGNE_UPDATE_MODAL = "LISTE_ENSEIGNE_UPDATE_MODAL";
export const LISTE_ENSEIGNE_ADD_MODAL = "LISTE_ENSEIGNE_ADD_MODAL";
export const LISTE_ENSEIGNE_UPDATE_LOADING = "LISTE_ENSEIGNE_UPDATE_LOADING";
export const LISTE_ENSEIGNE_ADD_LOADING = "LISTE_ENSEIGNE_ADD_LOADING";
export const LISTE_ENSEIGNE_ADD_SUCCESS = "LISTE_ENSEIGNE_ADD_SUCCESS";
export const LISTE_ENSEIGNE_ADD_FAIL = "LISTE_ENSEIGNE_ADD_FAIL";
export const LISTE_ENSEIGNE_UPDATE_SUCCESS = "LISTE_ENSEIGNE_UPDATE_SUCCESS";
export const LISTE_ENSEIGNE_UPDATE_FAIL = "LISTE_ENSEIGNE_UPDATE_FAIL";
export const LISTE_ENSEIGNE_ITEM_LOADING = "LISTE_ENSEIGNE_ITEM_LOADING";
export const LISTE_ENSEIGNE_ITEM_SUCCESS = "LISTE_ENSEIGNE_ITEM_SUCCESS";
export const LISTE_ENSEIGNE_ITEM_FAIL = "LISTE_ENSEIGNE_ITEM_FAIL";

export const LISTE_CATEGORIEENSEIGNE_LOADING = "LISTE_CATEGORIEENSEIGNE_LOADING";
export const LISTE_CATEGORIEENSEIGNE_SUCCESS = "LISTE_CATEGORIEENSEIGNE_SUCCESS";
export const LISTE_CATEGORIEENSEIGNE_FAIL = "LISTE_CATEGORIEENSEIGNE_FAIL";
export const LISTE_CATEGORIEENSEIGNE_DELETE_LOADING = "LISTE_CATEGORIEENSEIGNE_DELETE_LOADING";
export const LISTE_CATEGORIEENSEIGNE_DELETE_SUCCESS = "LISTE_CATEGORIEENSEIGNE_DELETE_SUCCESS";
export const LISTE_CATEGORIEENSEIGNE_DELETE_FAIL = "LISTE_CATEGORIEENSEIGNE_DELETE_FAIL";

export const LISTE_DISTRIBUTIONCHANNEL_LOADING = "LISTE_DISTRIBUTIONCHANNEL_LOADING";
export const LISTE_DISTRIBUTIONCHANNEL_SUCCESS = "LISTE_DISTRIBUTIONCHANNEL_SUCCESS";
export const LISTE_DISTRIBUTIONCHANNEL_FAIL = "LISTE_DISTRIBUTIONCHANNEL_FAIL";

export const LISTE_MARQUE_LOADING = "LISTE_MARQUE_LOADING";
export const LISTE_MARQUE_SUCCESS = "LISTE_MARQUE_SUCCESS";
export const LISTE_MARQUE_FAIL = "LISTE_MARQUE_FAIL";
export const LISTE_MARQUE_ADD_LOADING = "LISTE_MARQUE_ADD_LOADING";
export const LISTE_MARQUE_ADD_SUCCESS = "LISTE_MARQUE_ADD_SUCCESS";
export const LISTE_MARQUE_ADD_FAIL = "LISTE_MARQUE_ADD_FAIL";
export const LISTE_MARQUE_UPDATE_LOADING = "LISTE_MARQUE_UPDATE_LOADING";
export const LISTE_MARQUE_UPDATE_SUCCESS = "LISTE_MARQUE_UPDATE_SUCCESS";
export const LISTE_MARQUE_UPDATE_FAIL = "LISTE_MARQUE_UPDATE_FAIL";
export const LISTE_MARQUE_ADD_MODAL = "LISTE_MARQUE_ADD_MODAL";
export const LISTE_MARQUE_UPDATE_MODAL = "LISTE_MARQUE_UPDATE_MODAL";

export const LISTE_ROUTEPLAN_LOADING = "LISTE_ROUTEPLAN_LOADING";
export const LISTE_ROUTEPLAN_SUCCESS = "LISTE_ROUTEPLAN_SUCCESS";
export const LISTE_ROUTEPLAN_FAIL = "LISTE_ROUTEPLAN_FAIL";
export const LISTE_ROUTEPLAN_ADD_LOADING = "LISTE_ROUTEPLAN_ADD_LOADING";
export const LISTE_ROUTEPLAN_ADD_SUCCESS = "LISTE_ROUTEPLAN_ADD_SUCCESS";
export const LISTE_ROUTEPLAN_ADD_FAIL = "LISTE_ROUTEPLAN_ADD_FAIL";
export const LISTE_ROUTEPLAN_UPDATE_LOADING = "LISTE_ROUTEPLAN_UPDATE_LOADING";
export const LISTE_ROUTEPLAN_UPDATE_SUCCESS = "LISTE_ROUTEPLAN_UPDATE_SUCCESS";
export const LISTE_ROUTEPLAN_UPDATE_FAIL = "LISTE_ROUTEPLAN_UPDATE_FAIL";
export const LISTE_ROUTEPLAN_ADD_MODAL = "LISTE_ROUTEPLAN_ADD_MODAL";
export const LISTE_ROUTEPLAN_UPDATE_MODAL = "LISTE_ROUTEPLAN_UPDATE_MODAL";

export const LISTE_USERS_LOADING = "LISTE_USERS_LOADING";
export const LISTE_USERS_SUCCESS = "LISTE_USERS_SUCCESS";
export const LISTE_USERS_COMMERCIAL_SUCCESS = "LISTE_USERS_COMMERCIAL_SUCCESS";
export const LISTE_USERS_DISTRIBUTOR_SUCCESS = "LISTE_USERS_DISTRIBUTOR_SUCCESS";
export const LISTE_USERS_FAIL = "LISTE_USERS_FAIL";
export const LISTE_USERS_ADD_MODAL = "LISTE_USERS_ADD_MODAL";
export const LISTE_USERS_UPDATE_MODAL = "LISTE_USERS_UPDATE_MODAL";
export const LISTE_USERS_IMPORT_DISTRIBUTORSCSV_MODAL = "LISTE_USERS_IMPORT_DISTRIBUTORSCSV_MODAL";
export const LISTE_USERS_IMPORT_DISTRIBUTORSCSV_LOADING = "LISTE_USERS_IMPORT_DISTRIBUTORSCSV_LOADING";
export const LISTE_USERS_TRACKING_ITEM_MODAL = "LISTE_USERS_TRACKING_ITEM_MODAL";
export const LISTE_USERS_DISTR_FIND_DETAILSPLAFOND_SUCCESS = "LISTE_USERS_DISTR_FIND_DETAILSPLAFOND_SUCCESS";
export const LISTE_USERS_DISTR_FIND_DETAILSPLAFOND_FAIL = "LISTE_USERS_DISTR_FIND_DETAILSPLAFOND_FAIL";
export const LISTE_USERS_DISTR_FIND_DETAILSPLAFOND_LOADING = "LISTE_USERS_DISTR_FIND_DETAILSPLAFOND_LOADING";
export const LISTE_USERS_ITEM_UPDATE_SUCCESS = "LISTE_USERS_ITEM_UPDATE_SUCCESS";
export const LISTE_USERS_ITEM_UPDATE_FAIL = "LISTE_USERS_ITEM_UPDATE_FAIL";
export const LISTE_USERS_ITEM_UPDATE_LOADING = "LISTE_USERS_ITEM_UPDATE_LOADING";
export const LISTE_USERS_LOCALISATIONS_LOADING = "LISTE_USERS_LOCALISATIONS_LOADING";
export const LISTE_USERS_LOCALISATIONS_SUCCESS = "LISTE_USERS_LOCALISATIONS_SUCCESS";
export const LISTE_USERS_LOCALISATIONS_FAIL = "LISTE_USERS_LOCALISATIONS_FAIL";

export const LISTE_ARTICLES_LOADING = "LISTE_ARTICLES_LOADING";
export const LISTE_ARTICLES_SUCCESS = "LISTE_ARTICLES_SUCCESS";
export const LISTE_ARTICLES_WITHCHANNEL_SUCCESS = "LISTE_ARTICLES_WITHCHANNEL_SUCCESS";
export const LISTE_ARTICLES_WITHCHANNEL_FAIL = "LISTE_ARTICLES_WITHCHANNEL_FAIL";
export const LISTE_ARTICLES_2_SUCCESS = "LISTE_ARTICLES_2_SUCCESS";
export const LISTE_ARTICLES_FAIL = "LISTE_ARTICLES_FAIL";
export const LISTE_ARTICLES_ADD_MODAL = "LISTE_ARTICLES_ADD_MODAL";
export const LISTE_ARTICLES_ADDCHANNEL_MODAL = "LISTE_ARTICLES_ADDCHANNEL_MODAL";
export const LISTE_ARTICLES_DETAILS_ITEM_MODAL = "LISTE_ARTICLES_DETAILS_ITEM_MODAL";
export const LISTE_ARTICLES_IMPORTCSV_MODAL = "LISTE_ARTICLES_IMPORTCSV_MODAL";
export const LISTE_ARTICLES_ADD_SUCCESS = "LISTE_ARTICLES_ADD_SUCCESS";
export const LISTE_ARTICLES_ADDCHANNEL_SUCCESS = "LISTE_ARTICLES_ADDCHANNEL_SUCCESS";
export const LISTE_ARTICLES_ADDCHANNEL_FAIL = "LISTE_ARTICLES_ADDCHANNEL_FAIL";
export const LISTE_ARTICLES_IMPORTCSV_FAIL = "LISTE_ARTICLES_IMPORTCSV_FAIL";
export const LISTE_ARTICLES_IMPORTCSV_SUCCESS = "LISTE_ARTICLES_IMPORTCSV_SUCCESS";
export const LISTE_ARTICLES_IMPORTCSV_LOADING = "LISTE_ARTICLES_IMPORTCSV_LOADING";
export const LISTE_ARTICLES_IMPORT_CODETARIFS_CSV_LOADING = "LISTE_ARTICLES_IMPORT_CODETARIFS_CSV_LOADING";
export const LISTE_ARTICLES_IMPORT_CODETARIFS_CSV_FAIL = "LISTE_ARTICLES_IMPORT_CODETARIFS_CSV_FAIL";
export const LISTE_ARTICLES_IMPORT_CODETARIFS_CSV_SUCCESS = "LISTE_ARTICLES_IMPORT_CODETARIFS_CSV_SUCCESS";
export const LISTE_ARTICLES_UPDATE_ARTICLECHANNEL_LOADING = "LISTE_ARTICLES_UPDATE_ARTICLECHANNEL_LOADING";
export const LISTE_ARTICLES_UPDATE_ARTICLECHANNEL_SUCCESS = "LISTE_ARTICLES_UPDATE_ARTICLECHANNEL_SUCCESS";
export const LISTE_ARTICLES_UPDATE_ARTICLECHANNEL_FAIL = "LISTE_ARTICLES_UPDATE_ARTICLECHANNEL_FAIL";
export const LISTE_ARTICLES_IMPORT_CODETARIFS_CSV_MODAL = "LISTE_ARTICLES_IMPORT_CODETARIFS_CSV_MODAL";

export const LISTE_OBJECTIVES_LOADING = "LISTE_OBJECTIVES_LOADING";
export const LISTE_OBJECTIVES_CRITERIA_SUCCESS = "LISTE_OBJECTIVES_CRITERIA_SUCCESS";
export const LISTE_OBJECTIVES_CRITERIA_FAIL = "LISTE_OBJECTIVES_CRITERIA_FAIL";
export const LISTE_OBJECTIVES_SUCCESS = "LISTE_OBJECTIVES_SUCCESS";
export const LISTE_OBJECTIVES_FAIL = "LISTE_OBJECTIVES_FAIL";
export const LISTE_OBJECTIVES_ADD_MODAL = "LISTE_OBJECTIVES_ADD_MODAL";
export const LISTE_OBJECTIVES_ADD_SUCCESS = "LISTE_OBJECTIVES_ADD_SUCCESS";

export const LISTE_PROMOTIONS_LOADING = "LISTE_PROMOTIONS_LOADING";
export const LISTE_PROMOTIONS_SUCCESS = "LISTE_PROMOTIONS_SUCCESS";
export const LISTE_PROMOTIONS_FAIL = "LISTE_PROMOTIONS_FAIL";
export const LISTE_PROMOTIONS_ADD_LOADING = "LISTE_PROMOTIONS_ADD_LOADING";
export const LISTE_PROMOTIONS_ADD_SUCCESS = "LISTE_PROMOTIONS_ADD_SUCCESS";
export const LISTE_PROMOTIONS_ADD_FAIL = "LISTE_PROMOTIONS_ADD_FAIL";
export const LISTE_PROMOTIONS_ADD_MODAL = "LISTE_PROMOTIONS_ADD_MODAL";
export const LISTE_PROMOTIONS_ITEM_SETSTATUS_LOADING = "LISTE_PROMOTIONS_ITEM_STATUS_LOADING";
export const LISTE_PROMOTIONS_ITEM_SETSTATUS_SUCCESS = "LISTE_PROMOTIONS_ITEM_SETSTATUS_SUCCESS";
export const LISTE_PROMOTIONS_ITEM_SETSTATUS_FAIL = "LISTE_PROMOTIONS_ITEM_SETSTATUS_FAIL";
export const LISTE_PROMOTIONS_ITEM_SETSTATUS_ACTIVER_MODAL = "LISTE_PROMOTIONS_ITEM_SETSTATUS_ACTIVER_MODAL";
export const LISTE_PROMOTIONS_ITEM_SETSTATUS_DESACTIVER_MODAL = "LISTE_PROMOTIONS_ITEM_SETSTATUS_DESACTIVER_MODAL";

export const LISTE_DISCOUNTS_LOADING = "LISTE_DISCOUNTS_LOADING";
export const LISTE_DISCOUNTS_SUCCESS = "LISTE_DISCOUNTS_SUCCESS";
export const LISTE_DISCOUNTS_FAIL = "LISTE_DISCOUNTS_FAIL";
export const LISTE_DISCOUNTS_ADD_LOADING = "LISTE_DISCOUNTS_ADD_LOADING";
export const LISTE_DISCOUNTS_ADD_SUCCESS = "LISTE_DISCOUNTS_ADD_SUCCESS";
export const LISTE_DISCOUNTS_ADD_FAIL = "LISTE_DISCOUNTS_ADD_FAIL";
export const LISTE_DISCOUNTS_ADD_MODAL = "LISTE_DISCOUNTS_ADD_MODAL";
export const LISTE_DISCOUNTS_ITEM_SETSTATUS_LOADING = "LISTE_DISCOUNTS_ITEM_SETSTATUS_LOADING";
export const LISTE_DISCOUNTS_ITEM_SETSTATUS_SUCCESS = "LISTE_DISCOUNTS_ITEM_SETSTATUS_SUCCESS";
export const LISTE_DISCOUNTS_ITEM_SETSTATUS_FAIL = "LISTE_DISCOUNTS_ITEM_SETSTATUS_FAIL";
export const LISTE_DISCOUNTS_ITEM_SETSTATUS_ACTIVER_MODAL = "LISTE_DISCOUNTS_ITEM_SETSTATUS_ACTIVER_MODAL";
export const LISTE_DISCOUNTS_ITEM_SETSTATUS_DESACTIVER_MODAL = "LISTE_DISCOUNTS_ITEM_SETSTATUS_DESACTIVER_MODAL";

export const LISTE_ORDERS_LOADING = "LISTE_ORDERS_LOADING";
export const LISTE_ORDERS_EXPORT_LOADING = "LISTE_ORDERS_EXPORT_LOADING";
export const LISTE_ORDERS_ITEM_DELIVERY_LOADING = "LISTE_ORDERS_ITEM_DELIVERY_LOADING";
export const LISTE_ORDERS_ITEM_STATE_LOADING = "LISTE_ORDERS_ITEM_STATE_LOADING";
export const LISTE_ORDERS_SUCCESS = "LISTE_ORDERS_SUCCESS";
export const LISTE_ORDERS_FAIL = "LISTE_ORDERS_FAIL";
export const LISTE_ORDERS_ADD_MODAL = "LISTE_ORDERS_ADD_MODAL";
export const LISTE_ORDERS_ITEM_DELIVERY_CONFIRM = "LISTE_ORDERS_ITEM_DELIVERY_CONFIRM";
export const LISTE_ORDERS_ITEM_DELIVERYBACK_ADD = "LISTE_ORDERS_ITEM_DELIVERYBACK_ADD";
export const LISTE_ORDERS_ITEM_DELIVERYBACK_SHOW = "LISTE_ORDERS_ITEM_DELIVERYBACK_SHOW";
export const LISTE_ORDERS_ITEM_DELIVERY_CONFIRM_LOADING = "LISTE_ORDERS_ITEM_DELIVERY_CONFIRM_LOADING";
export const LISTE_ORDERS_ITEM_DELIVERY_LINEBACK_LOADING = "LISTE_ORDERS_ITEM_DELIVERY_LINEBACK_LOADING";
export const LISTE_ORDERS_ADD_DELIVERY_MODAL = "LISTE_ORDERS_ADD_DELIVERY_MODAL";
export const LISTE_ORDERS_ADD_ECHEANCE_MODAL = "LISTE_ORDERS_ADD_ECHEANCE_MODAL";
export const LISTE_ORDERS_ADD_PAIEMENT_SUCCESS = "LISTE_ORDERS_ADD_PAIEMENT_SUCCESS";
export const LISTE_ORDERS_ADD_SUCCESS = "LISTE_ORDERS_ADD_SUCCESS";
export const LISTE_ORDERS_ADD_FAIL = "LISTE_ORDERS_ADD_FAIL";
export const LISTE_ORDERS_ITEM_SUCCESS = "LISTE_ORDERS_ITEM_SUCCESS";
export const LISTE_ORDERS_ITEM_FAIL = "LISTE_ORDERS_ITEM_FAIL";
export const LISTE_ORDERS_DETAILS_MODAL = "LISTE_ORDERS_DETAILS_MODAL";
export const LISTE_ORDERS_ITEM_SETSTATE_SUCCESS = "LISTE_ORDERS_ITEM_SETSTATE_SUCCESS";
export const LISTE_ORDERS_ITEM_SETSTATE_FAIL = "LISTE_ORDERS_ITEM_SETSTATE_FAIL";
export const LISTE_ORDERS_ITEM_DELIVERY_ADD_SUCCESS = "LISTE_ORDERS_ITEM_DELIVERY_ADD_SUCCESS";
export const LISTE_ORDERS_ITEM_ECHEANCE_ADD_SUCCESS = "LISTE_ORDERS_ITEM_ECHEANCE_ADD_SUCCESS";
export const LISTE_ORDERS_ITEM_DELIVERY_SUCCESS = "LISTE_ORDERS_ITEM_DELIVERY_SUCCESS";
export const LISTE_ORDERS_ITEM_DELIVERY_FAIL = "LISTE_ORDERS_ITEM_DELIVERY_FAIL";

export const LISTE_GROUPUSERS_LOADING = "LISTE_GROUPUSERS_LOADING";
export const LISTE_GROUPUSERS_SUCCESS = "LISTE_GROUPUSERS_SUCCESS";
export const LISTE_GROUPUSERS_FAIL = "LISTE_GROUPUSERS_FAIL";

export const LISTE_TYPEECHEANCE_SUCCESS = "LISTE_TYPEECHEANCE_SUCCESS";
export const LISTE_TYPEECHEANCE_FAIL = "LISTE_TYPEECHEANCE_FAIL";
export const LISTE_TYPEECHEANCE_LOADING = "LISTE_TYPEECHEANCE_LOADING";

export const LISTE_MYAPPSETTINGS_ONE_SUCCESS = "LISTE_MYAPPSETTINGS_ONE_SUCCESS";
export const LISTE_MYAPPSETTINGS_ONE_FAIL = "LISTE_MYAPPSETTINGS_ONE_FAIL";

export const LISTE_STATISTICS_DASHBOARDCARDS_SUCCESS = "LISTE_STATISTICS_DASHBOARDCARDS_SUCCESS";
export const LISTE_STATISTICS_DASHBOARDCARDS_FAIL = "LISTE_STATISTICS_DASHBOARDCARDS_FAIL";
export const LISTE_STATISTICS_DASHBOARDCARDS_LOADING = "LISTE_STATISTICS_DASHBOARDCARDS_LOADING";
export const LISTE_STATISTICS_ORDERSTATELEVELPROGRESS_SUCCESS = "LISTE_STATISTICS_ORDERSTATELEVELPROGRESS_SUCCESS";
export const LISTE_STATISTICS_ORDERSTATELEVELPROGRESS_FAIL = "LISTE_STATISTICS_ORDERSTATELEVELPROGRESS_FAIL";
export const LISTE_STATISTICS_ORDERSTATELEVELPROGRESS_LOADING = "LISTE_STATISTICS_ORDERSTATELEVELPROGRESS_LOADING";

export const LISTE_CODETARIF_LOADING = "LISTE_CODETARIF_LOADING";
export const LISTE_CODETARIF_SUCCESS = "LISTE_CODETARIF_SUCCESS";
export const LISTE_CODETARIF_FAIL = "LISTE_CODETARIF_FAIL";
