
// let base = 'http://sfa.youmsi-tech.com/index.php/api/v1';
let base = window.env.BASE_URL;

module.exports = {
    API_URL : {
        BASE : base,
        AUTH :  'auth',
        CATEGORIES : 'categories',
        CATEGORIEENSEIGNES : 'categorieEnseignes',
        CODETARIF : 'codeTarif',
        ENSEIGNES : 'enseignes',
        MARQUES : 'marques',
        MYAPPSETTINGS : 'Myappsettings',
        ROUTEPLAN : 'routeplan',
        USERS : 'users',
        OBJECTIVES : 'objectives',
        ORDERS : 'commandes',
        PROMOTION : 'promotion',
        TYPEECHEANCE : 'typeecheance',
        DELIVERY : 'delivery',
        GROUPUSERS : 'groupUsers',
        ARTICLES : 'articles',
        DISTRBUTIONCHANNEL : 'distributionchannel',
        STATISTICS : 'statistics',
    },
    LOCAL_STORAGE : {
        USER : "USER",
    }
};
