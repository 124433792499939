import {
    LISTE_CODETARIF_FAIL,
    LISTE_CODETARIF_SUCCESS,
    LISTE_CODETARIF_LOADING,
} from "../actions/types";

const initialState = {
    codetarifs: [],
    totalRows: 0,
    totalPages: 0,
    loading: false,
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case LISTE_CODETARIF_SUCCESS:
            return {
                ...state,
                codetarifs: payload.codetarifs,
                totalRows: payload.totalRows,
                totalPages: payload.totalPages,
                loading: false
            };

        case LISTE_CODETARIF_FAIL:
            return {
                ...state,
                codetarifs: payload.message,
                loading: false
            };

        case LISTE_CODETARIF_LOADING:
            return {
                ...state,
                // categories: payload?.loading ? [] : state.categories,
                loading: payload.loading
            };

        default:
            return state;
    }
}
